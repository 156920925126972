import React from "react";
import { Progress, Tooltip } from 'antd';

import { useQuery }              from "@apollo/client";
import { GET_CLOUDINARY_REPORT } from "graphql/query/cloudinary-report-gql";

import CloudinaryReport from "..";
import { Localize }     from "components/service";


const CloudinaryReportElementGeneral = () => {

    const { data: { cloudinaryReport } = {} } = useQuery( GET_CLOUDINARY_REPORT, {
        variables: {
            type: CloudinaryReport.Const.typeGeneral
        },
        fetchPolicy     : 'cache-and-network',
        nextFetchPolicy : 'cache-first'
    });

    const conicColors = {
        '0%'   : '#52c41a',
        '75%'  : '#c3c76d',
        '90%'  : 'gold',
        '100%' : '#d12e55',
    };

    return(
        <Tooltip color="#f22991" title={ 
            <>
                <div className="d-flex justify-content-between">
                    <Localize>Used</Localize>:
                    <b>{ cloudinaryReport?.credits_used_percent ?? 0 }%</b>
                </div>
                <div className="d-flex justify-content-between">
                    <Localize>Transformations</Localize>:
                    <b>{ cloudinaryReport?.transformations_usage ?? 0 }</b>
                </div>
                <div className="d-flex justify-content-between">
                    <Localize>Used credits</Localize>:
                    <b>{ cloudinaryReport?.credits_usage ?? 0 }</b>
                </div>
                <div className="d-flex justify-content-between">
                    <Localize>Credit limit</Localize>:
                    <b>{ cloudinaryReport?.credits_limit ?? 0 }</b>
                </div>
            </> 
        }>
            <span style={{ fontWeight: "bold" }}>Credit Usage For Last 30 Days</span>
            <div className="d-flex justify-content-between">
                <Progress
                    className   = "cloudinary-report-general"
                    percent     = { cloudinaryReport?.credits_used_percent ?? 0 }
                    status      = "active"
                    strokeColor = { conicColors }
                />
            </div>
        </Tooltip>
    )

}

export default CloudinaryReportElementGeneral;